export const API_BASE_PATH = process.env.API_BASE_PATH
export const INTER_GATEWAY_PATH = process.env.INTER_GATEWAY_PATH
export const INTER_GATEWAY_PATH_PRD_TESTE = process.env.INTER_GATEWAY_PATH_PRD_TESTE
export const INTER_GATEWAY_WEB_PATH = process.env.INTER_GATEWAY_WEB_PATH
export const APIS_BASE_PATH = process.env.APIS_BASE_PATH
export const API_CONSIGNADO = process.env.API_CONSIGNADO
export const IB_BASE_PATH = process.env.IB_BASE_PATH
export const CHAT_APP_ID = process.env.CHAT_APP_ID
export const SIMULADOR_FINANCIAMENTO_IMOBILIARIO = process.env.SIMULADOR_FINANCIAMENTO_IMOBILIARIO
export const SIMULADOR_IMOBILIARIO = process.env.SIMULADOR_IMOBILIARIO
export const SIMULADOR_HOME_EQUITY = process.env.SIMULADOR_HOME_EQUITY
export const SIMULADOR_HOME_EQUITY_PJ = process.env.SIMULADOR_HOME_EQUITY_PJ
export const SIMULADOR_PORTABILIDADE_HOME_EQUITY = process.env.SIMULADOR_PORTABILIDADE_HOME_EQUITY
export const SIMULADOR_PORTABILIDADE_IMOBILIARIO = process.env.SIMULADOR_PORTABILIDADE_IMOBILIARIO
export const SIMULADOR_PORTABILIDADE_CONSIGNADO = process.env.SIMULADOR_PORTABILIDADE_CONSIGNADO
export const SIMULADOR_CREDITO_CONSIGNADO = process.env.SIMULADOR_CREDITO_CONSIGNADO
export const URL_MOBILE_INTERCEL = process.env.URL_MOBILE_INTERCEL
export const PATH_OPEN_ACCOUNT = process.env.PATH_OPEN_ACCOUNT
export const INTER_GATEWAY_WEB_PATH_V2 = process.env.INTER_GATEWAY_WEB_PATH_V2
export const API_CONSIGNADO_NOVO = process.env.API_CONSIGNADO_NOVO
export const API_CONSIGNADO_PRIVADO = process.env.API_CONSIGNADO_PRIVADO
export const SITE_URL_INTER_INVEST = process.env.URL_INTER_INVEST
export const SITE_URL_PORTAL_NOTICIAS = process.env.URL_PORTAL_NOTICIAS
export const SIMULADOR_ANTECIPACAO_FGTS = process.env.SIMULADOR_ANTECIPACAO_FGTS
export const SIDLE_PATH = process.env.SIDLE_PATH
export const LP_OPEN_ACCOUNT_PJ = process.env.OPEN_ACCOUNT_PJ
export const INTER_GATEWAY_WEB_PATH_WHATSAPP = process.env.INTER_GATEWAY_WEB_PATH_WHATSAPP
export const WEB_API_CRIPTO = process.env.INTER_GATEWAY_PATH_API_CRIPTO
export const INTER_GATEWAY_PATH_API_ACESSO_LIBRAS = process.env.INTER_GATEWAY_PATH_API_ACESSO_LIBRAS

export const DOLAR_QUOTATION = `${INTER_GATEWAY_PATH}/institucional/cotacoes/v1/dolar`
export const CONTACT_FORM_POST = `${INTER_GATEWAY_PATH}/institucional/contato/v1/forms`
export const CONTACT_FORM_POST_V6 = `${INTER_GATEWAY_PATH}/institucional/contato/v2/canais`
export const CONTACT_FORM_POST_V2 = `${INTER_GATEWAY_PATH}/institucional/contato/v1/leads?encoding=UTF-8`
export const CONTACT_FORM_POST_V3 = `${INTER_GATEWAY_WEB_PATH}/api/v1/webcollection` // Webcollection
export const CONTACT_FORM_POST_V4 = `${INTER_GATEWAY_WEB_PATH}/api/v1/dataextensions` // DEManager
export const CONTACT_FORM_POST_V5 = `${INTER_GATEWAY_WEB_PATH_V2}/institucional/contato/v2/canais`
export const UPLOAD_VIDEO_TO_YOUTUBE = `${INTER_GATEWAY_PATH_PRD_TESTE}/api/uploader-youtube-video` // YouTube video
export const BASE_URL_USEND_API = 'https://m-api.usend.com/V10/api'
export const BFF_SITE_INSTITUCIONAL = `${INTER_GATEWAY_PATH}/api/bff-site-institucional`

export const CONTACT_FORM_POST_V7 = `${INTER_GATEWAY_WEB_PATH}/institucional/contato/v2/canais/lead-lp-win`

export const CONTACT = `${API_BASE_PATH}/web/solicitacao-contato/v1/solicitacao`
export const CONTACT_FORM_BLACK = `${INTER_GATEWAY_PATH}/institucional/contato/v1/canais/site-case-black`
export const CONTACT_FORM_CREDIT_PJ = `${INTER_GATEWAY_PATH}/institucional/contato/v2/canais/lead-emprestimo-corporativo`
export const CONTACT_FORM_CONSIGNADO_PARCEIRO = `${INTER_GATEWAY_PATH}/institucional/contato/v2/canais/lead-consignado-parceiro`
export const TABLE_INVESTMENTS = `${API_BASE_PATH}/web/investimento/v1/taxas`
export const FAREMETER = 'https://api.bancointer.com.br/web/tarifometro'
export const PORTABILITY_FAREMETER = process.env.PORTABILITY_FAREMETER
export const FUNDS = '/mocks/InvestimentsFund.json'
export const STATES = '/mocks/States.json'
export const QUIZPT = '/quiz/quiz-pt.json'
export const QUIZEN = '/quiz/quiz-en.json'
export const INVESTMENTS = '/funds'
export const PROCESS_STATUS = `${PATH_OPEN_ACCOUNT}/abertura-conta-pj/v1/ficha-cadastral/processo-abertura/status`
export const FILES = '/files'
export const QUIZ_GLOBAL_ACCOUNT = '/quiz/quiz-global-account.json'
export const PATH_SIDLE = `${SIDLE_PATH}/api/1/lounge-key/com.inter.ecm.produtoseservicos.cartao/acessoLoungeKey/armazenarFormulario`
export const RECAPTCHA_SYDLE_ONE = `${SIDLE_PATH}/api/1/lounge-key/com.inter.utilitarios/reCAPTCHA/retrieve`
export const API_WHATSAPP = `${INTER_GATEWAY_WEB_PATH_WHATSAPP}/api/v1/whatsapp-facedes/`

export const IB_LOGIN = `${IB_BASE_PATH}/login`
export const OPEN_ACCOUNT = `${process.env.OPEN_ACCOUNT}/open-account`
export const OPEN_ACCOUNT_V2 = `${INTER_GATEWAY_PATH}/institucional/contato/v2/open-account`
export const SITE_BASE_URL = process.env.SITE_BASE_URL
export const REAL_ESTATE_INDICATIONS = `${API_BASE_PATH}/credito-imobiliario/client-capture/v1/indications`

// Convite CDPro
export const CDPRO_BASE_PATH = process.env.CDPRO_BASE_PATH
export const CDPRO_INVITE = `${CDPRO_BASE_PATH}/fichas-cadastrais/processo-antigo/convites`
export const CDPRO_TOKEN = `${CDPRO_BASE_PATH}/auth/oauth/v2/token`
export const CDPRO_PREFERENCES = `${CDPRO_BASE_PATH}/convites/interesses`
export const CDPRO_NEW_INVITE = `${CDPRO_BASE_PATH}/convites`

export const API_CONSIGNADO_NOVO_PATH = `${API_CONSIGNADO_NOVO}`
export const API_CONSIGNADO_PRIVADO_PATH = `${API_CONSIGNADO_PRIVADO}`

export const API_OUVIDORIA = `${INTER_GATEWAY_WEB_PATH_V2}/institucional/contato/v1/canais`

export const WEB_API_LP_CRIPTO = `${WEB_API_CRIPTO}`

export const WEB_API_LP_ATENDIMENTO_LIBRAS = `${INTER_GATEWAY_PATH_API_ACESSO_LIBRAS}`
