import React from 'react'
import Helmet from 'react-helmet'

import useIntercomChat from '../hooks/useIntercomChat'

const Chat = () => {
  const resizeWindow = true
  const openChatCustomMessage = ''

  if (typeof window === 'undefined' || typeof document === 'undefined') {
    return null
  }

  useIntercomChat(resizeWindow, openChatCustomMessage)

  return (
    <div>
      <Helmet title='Chat Online' />
      <center>Carregando...</center>
    </div>
  )
}

export default Chat
