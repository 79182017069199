import { useEffect } from 'react'
import * as URLs from 'config/api/Urls'

const intercomSettings = {
  app_id: URLs.CHAT_APP_ID,
}

function useIntercomChat (
  resizeWindow = false,
  openChatCustomMessage = null,
  openOnStart = true,
  customSettings = {},
) {
  useEffect(() => {
    const setSizeDefault = function () { window.resizeTo(445, 620) }
    const d = document
    const w = window
    const ic = w.Intercom
    const settings = { ...intercomSettings, ...customSettings }

    function onWindowLoad () {
      const s = d.createElement('script')
      const x = d.getElementsByTagName('script')[0]

      s.type = 'text/javascript'
      s.async = true
      s.src = 'https://widget.intercom.io/widget/' + URLs.CHAT_APP_ID
      x.parentNode.insertBefore(s, x)
    }

    const intercomWindow = function () {
      let i
      if (typeof ic === 'function') {
        ic('reattach_activator')
        ic('update', settings)
      } else {
        i = function () {
          i.c(arguments)
        }
        i.q = []
        i.c = function (args) {
          i.q.push(args)
        }
        w.Intercom = i
        onWindowLoad()
      }
    }

    intercomWindow()

    w.Intercom('onHide', window.close)

    if (resizeWindow) {
      window.onresize = setSizeDefault
      window.onclick = setSizeDefault
    }

    w.Intercom('boot', settings)

    const openWindow = () => {
      if (!w.Intercom || !w.Intercom.booted) {
        return setTimeout(openWindow, 100)
      }

      if (openChatCustomMessage) {
        w.Intercom('showNewMessage', openChatCustomMessage)
      } else {
        w.Intercom('show')
      }
    }

    if (openOnStart) {
      openWindow()
    }

    return () => window.Intercom('shutdown')
  }, [ openChatCustomMessage ])
}

export default useIntercomChat
